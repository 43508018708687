import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import "./App.css";
import "./config";
import socketIOClient from 'socket.io-client';
import PoolService from "./app/services/pool.service";
import EventBus from "./app/common/eventBus";
import {
    setRooms,
    addMessageToRoom
} from "./app/slices/chat";
import {
    getPoolsEvents,
    getPoolsInWhichIamJoined
} from "./app/slices/pool";
import LoadingBar from 'react-top-loading-bar'
import $ from 'jquery';


import FrontendLayout from './app/layouts/frontend/frontend';

import Login from "./app/pages/login/Login";
import Register from "./app/pages/register/Register";
import ForgotPassword from "./app/pages/forgotPassword/forgotPassword";
import ChangePasswordRequest from "./app/pages/changePasswordRequest/changePasswordRequest";
import Dashboard from "./app/pages/dashboard/dashboard";
import Account from './app/pages/account/account';
import PoolDetails from './app/pages/poolDetails/poolDetails';
import CreatePool from './app/pages/createPool/createPool';
import Notifications from './app/pages/notifications/notifications';
import Support from './app/pages/support/support';
import Pools from "./app/pages/pools/pools";
import Payout from "./app/pages/payout/payout";
import PoolEdit from "./app/pages/poolEdit/poolEdit";
import Refund from "./app/pages/refund/refund";
import Events from "./app/pages/events/events";
import CreateEvent from "./app/pages/createEvent/createEvent";
import EventDetails from "./app/pages/eventDetails/eventDetails";
import EventPayout from "./app/pages/eventPayout/eventPayout";
import ProfileView from "./app/pages/profileView/profileView";
import EventEdit from "./app/pages/eventEdit/eventEdit";
import PageNotFound from "./app/pages/pageNotFound/pageNotFound";
import OrderCancel from "./app/pages/order/cancel/cancel";
import OrderSuccess from "./app/pages/order/success/success";


const App = () => {
    const dispatch = useDispatch();
    const ENDPOINT = global.config.SOCKET_URL;
    const { progress } = useSelector((state) => state.loader);
    const { text, type } = useSelector((state) => state.message);
    const { rooms } = useSelector((state) => state.chat);
    const socketRef = useRef();

    const { user: currentUser } = useSelector((state) => state.auth);

    const [error, setError] = useState('');

    useEffect(() => {

        if (text) {
            createNotification(type, text);
        }

    }, [text])

    useEffect(() => {
        socketRef.current = socketIOClient(ENDPOINT);
        socketRef.current.on('message', onMessage);
        return () => {
            socketRef.current.disconnect();
            socketRef.current.off('message', onMessage);
        };
    }, []);

    useEffect(() => {

        if (currentUser) {

            let fetchRooms = async () => {

                const result1 = await dispatch(getPoolsEvents({})).unwrap();

                const result2 = await dispatch(getPoolsInWhichIamJoined({ userId: currentUser.user.id })).unwrap();

                return [
                    ...result1.Events.map(e => {
                        return {
                            id: e.id,
                            roomId: `${e.id}_EVENT`,
                            name: e.name,
                            conversationType: 'EVENT',
                            unread: 0,
                            messages: []
                        }
                    }),
                    ...result2.Pools.map(p => {
                        return {
                            id: p.id,
                            roomId: `${p.id}_POOL`,
                            name: p.name,
                            conversationType: 'POOL',
                            unread: 0,
                            messages: []
                        }
                    })
                ];
            }

            fetchRooms().then(async (result) => {

                for await (const conversation of result) {

                    socketRef.current.emit('join', { user: JSON.stringify(currentUser.user), room: conversation }, error => {
                        if (error) {
                            setError(error);
                        }
                    });

                }

                dispatch(setRooms(result));

            });

        }

    }, [currentUser]);


    const sendMessage = (payload) => {
        const { roomId, message } = payload;
        socketRef.current.emit('sendMessage', { roomId: roomId, message: message }, error => {
            if (error) {
                setError(error);
            }
        });
    };

    function onMessage(payload) {
        const { roomId, message } = payload;
        dispatch(addMessageToRoom({ roomId: roomId, message: message }))
    }

    function createNotification(type, message) {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(message);
                break;
        }
    }

    return (
        <>
            <LoadingBar
                color='#C5914B'
                progress={progress}
            />
            <NotificationContainer />
            <Router>
                <Routes>
                    <Route element={<FrontendLayout />}>
                        <Route path="/" element={<Pools />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/change-password/:token" element={<ChangePasswordRequest />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/account" element={<Account />} />
                        <Route path="/profile" element={<ProfileView />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/notifications" element={<Notifications />} />
                        <Route path="/pools" element={<Pools />} />
                        <Route path="/pool-details" element={<PoolDetails sendMessage={sendMessage} />} />
                        <Route path="/create-pool" element={<CreatePool />} />
                        <Route path="/payout" element={<Payout />} />
                        <Route path="/pool-edit" element={<PoolEdit />} />
                        <Route path="/pool-refund" element={<Refund />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/create-event" element={<CreateEvent />} />
                        <Route path="/event-details" element={<EventDetails sendMessage={sendMessage} />} />
                        <Route path="/event-payout" element={<EventPayout />} />
                        <Route path="/event-edit" element={<EventEdit />} />
                        <Route path="/order/success" element={<OrderSuccess />} />
                        <Route path="/order/cancel" element={<OrderCancel />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                </Routes>
            </Router>
        </>
    );
};

export default App;