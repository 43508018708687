import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link, useLocation, useSearchParams } from "react-router-dom";



const OrderCancel = () => {
    const dispatch = useDispatch();


    return (<>
        <main>
            <section className="login-area">
                <div className="container">
                    <div className="row">
                        
                    </div>
                </div>
            </section>
        </main>
    </>);
}
export default OrderCancel 